import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';
import {MOBILE_WIDTH, TABLET_WIDTH, DESKTOP_WIDTH, XLARGE_WIDTH} from 'helpers/stableValues';

const breakpoints = [MOBILE_WIDTH, TABLET_WIDTH, DESKTOP_WIDTH, XLARGE_WIDTH];

const LazyImage = ({wrapperClass, wrapperStyle, src, title, alt, imgClass, width, height, style, srcSizes}) => {
  const refPlaceholder = React.useRef();
 
  const sizes = srcSizes?.map((size, index) => `(max-width: ${breakpoints[index]}px) ${size}px`)?.join(', ');
  const srcset = (srcSizes && src) ? breakpoints.map((breakpoint) =>
    `${src.replace(src.match(/\/w\d+\//), `/w${breakpoint}/`)} ${breakpoint}w`).join(', ') :
    undefined;

  const styleLazyLoad = Object.assign(
    {},
    !isNaN(width) && {
      width: `${width}px`
    },
    !isNaN(height) && {
      height: `${height}px`
    }
  );

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  const lazyImageWrapperClass = classnames(wrapperClass, {
    'position-relative': !wrapperClass?.includes('position-absolute')
  });

  return (
    <div className={lazyImageWrapperClass} style={wrapperStyle}>
      <div className="lazy-image-placeholder" ref={refPlaceholder} />
      <LazyLoad style={styleLazyLoad}>
        <img
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={src}
          alt={alt}
          title={title}
          className={imgClass}
          style={style}
          width={width}
          height={height}
          srcSet={srcset}
          sizes={sizes}
          loading='lazy'
        />
      </LazyLoad>
    </div>
  );
};


LazyImage.propTypes = {
  wrapperClass: PropTypes.string,
  wrapperStyle: PropTypes.object,
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  imgClass: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  style: PropTypes.object,
  srcSizes: PropTypes.arrayOf(PropTypes.number)
};

export default LazyImage;
