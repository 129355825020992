import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  outline,
  status,
  block,
  type,
  size,
  onClick,
  className,
  isLoading,
  tag: Tag,
  children,
  ...attributes
}) => {

  const classes = classNames( 'btn', className, {
    [`btn${outline ? '-outline': ''}-${status}`]: outline || status,
    [`btn-${size}`]: size,
    'btn-block': block,
    'is-loading disabled': isLoading
  });
  if (attributes.href && Tag === 'button') {
    Tag = 'a';
  }
  return (
    <Tag
      className={classes}
      type={Tag === 'button' ? type : undefined}
      onClick={isLoading ? undefined : onClick}
      {...attributes}>
      {children}
      {isLoading && <span className="btn-loader" />}
    </Tag>
  );
};

Button.defaultProps = {
  status: 'default',
  tag: 'button'
};

Button.propTypes = {
  status: PropTypes.oneOf([
    'primary', 'secondary', 'info', 'tertiary',
    'vivid-cerulean', 'danger', 'light', 'default'
  ]).isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  outline: PropTypes.bool,
  isLoading: PropTypes.bool,
  block: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['a', 'button'])
};

export default memo(Button);
