/* eslint max-len: "off" */
import {language} from 'helpers/stableValues';
import {affiliateHost} from 'ferries/helpers/common';

const defaultMenu = [{main: {name: 'Ferries', url: affiliateHost}}];

const eskyMenu = {
  en: [
    {main: {name: 'Ferries', url: affiliateHost}},
    {main: {name: 'Flights', url: 'https://www.esky.eu/flights', target: '_blank'}},
    {main: {name: 'Stays', url: 'https://www.esky.eu/stays', target: '_blank'}},
    {main: {name: 'Flight+Hotel', url: 'https://www.esky.eu/flight+hotel', target: '_blank'}},
    {main: {name: 'Cars', url: 'https://cars.esky.eu/?preflang=en&cor=mt&adplat=header&adcamp=whitelabelEU', target: '_blank'}},
    {main: {name: 'Deals', url: 'https://www.esky.eu/deals', target: '_blank'}},
    {main: {name: 'More'}, values: [
      {name: 'Attractions', url: 'https://www.getyourguide.pl/?partner_id=6PH6GQ5&cmp=EU&visitor-id=FNFWRCMH72JY52WQ3E0JGLHPH7F22NHJ&locale_autoredirect_optout=true', target: '_blank'},
      {name: 'Insurance', url: 'https://go.esky.co.uk/insurance', target: '_blank'},
      {name: 'Transfers', url: 'https://esky-eu.mozio.com/en-us/?campaign=website', target: '_blank'},
      {name: 'Parking', url: 'https://eskybymozio.travelcar.com/en-IE/?utm_source=partner&utm_medium=eskybymobio&utm_campaign=park-en&utm_content=website-home', target: '_blank'},
      {name: 'Mobile App', url: 'https://app.esky.com/?utm_source=esky.eu&utm_medium=menu', target: '_blank'},
      {name: 'Airlines', url: 'https://www.esky.eu/airlines', target: '_blank'},
      {name: 'Airports', url: 'https://www.esky.eu/airports', target: '_blank'},
      {name: 'Reviews', url: 'https://www.esky.eu/reviews', target: '_blank'},
      {name: 'Travel guide', url: 'https://www.esky.eu/travel-guide', target: '_blank'},
      {name: 'Flight radar', url: 'https://www.esky.eu/radar', target: '_blank'},
      {name: 'Travel rules', url: 'https://go.esky.com/travel-restrictions', target: '_blank'},
      {name: 'Booking management', url: 'https://www.esky.eu/userzone/login', target: '_blank'}
    ]}
  ],
  el: [
    {main: {name: 'Ακτοπλοϊκά', url: affiliateHost}},
    {main: {name: 'Αεροπορικά εισιτήρια', url: 'https://www.esky.gr/aeroporika-eisitiria', target: '_blank'}},
    {main: {name: 'Διαμονή', url: 'https://www.esky.gr/diamoni', target: '_blank'}},
    {main: {name: 'Πτήση+Ξενοδοχείο', url: 'https://www.esky.gr/ptisi+xenodohio', target: '_blank'}},
    {main: {name: 'Αυτοκίνητα', url: 'https://cars.esky.gr/?preflang=gr&adplat=hpHeader&&adcamp=whitelabelGR', target: '_blank'}},
    {main: {name: 'Προσφορές', url: 'https://www.esky.gr/prosfores', target: '_blank'}},
    {main: {name: 'Περισσότερα'}, values: [
      {name: 'Αξιοθέατα', url: 'https://www.getyourguide.com/?partner_id=6PH6GQ5&cmp=GR', target: '_blank'},
      {name: 'Ασφάλιση', url: 'https://go.esky.gr/asfalisi', target: '_blank'},
      {name: 'Μεταφορές', url: 'https://esky-gr.mozio.com/en-us/?campaign=website', target: '_blank'},
      {name: 'Στάθμευση', url: 'https://eskybymozio.travelcar.com/el-GR/?utm_source=partner&utm_medium=eskybymobio&utm_campaign=park-el&utm_content=website-home', target: '_blank'},
      {name: 'Εφαρμογή για κινητά', url: 'https://app.esky.gr/?utm_source=esky.gr&utm_medium=menu', target: '_blank'},
      {name: 'Αερομεταφορείς', url: 'https://www.esky.gr/aerometaforis', target: '_blank'},
      {name: 'Αεροδρόμια', url: 'https://www.esky.gr/aerodromia', target: '_blank'},
      {name: 'Κριτικές', url: 'https://www.esky.gr/kritikes', target: '_blank'},
      {name: 'Ταξιδιωτικός οδηγός', url: 'https://www.esky.gr/taxidiotikos-odigos', target: '_blank'},
      {name: 'Ραντάρ', url: 'https://www.esky.gr/radar', target: '_blank'},
      {name: 'Ταξιδιωτικοί κανονισμοί', url: 'https://go.esky.gr/travel-restrictions', target: '_blank'},
      {name: 'Διαχείριση κρατήσεων', url: 'https://www.esky.gr/userzone/login', target: '_blank'}
    ]}
  ],
  de: [
    {main: {name: 'Fähren', url: affiliateHost}},
    {main: {name: 'Flüge', url: 'https://www.eskytravel.de/fluge', target: '_blank'}},
    {main: {name: 'Unterkunft', url: 'https://www.eskytravel.de/unterkunft', target: '_blank'}},
    {main: {name: 'Flug+Hotel', url: 'https://www.eskytravel.de/flug+hotel', target: '_blank'}},
    {main: {name: 'Autos', url: 'https://www.rentalcars.com/?affiliateCode=eskygroup&adplat=hpheader&adcamp=whitelabelDE&cor=DE', target: '_blank'}},
    {main: {name: 'Schnäppchen', url: 'https://www.eskytravel.de/schnappchen ', target: '_blank'}},
    {main: {name: 'Mehr'}, values: [
      {name: 'Sehenswürdigkeiten', url: 'https://www.getyourguide.pl/?partner_id=6PH6GQ5&cmp=DE&visitor-id=7PJ6FUJGH1UXDF6Y714SR5RHMHGLQS00&locale_autoredirect_optout=true', target: '_blank'},
      {name: 'Versicherungen', url: 'https://go.eskytravel.de/reiseversicherungen', target: '_blank'},
      {name: 'Transfers', url: 'https://eskytravel-de.mozio.com/de-de/?campaign=website', target: '_blank'},
      {name: 'Parkplätze', url: 'https://parkplatze.eskytravel.de/ ', target: '_blank'},
      {name: 'Yachten', url: 'https://www.boataround.com/de?ref_afi=esky-de', target: '_blank'},
      {name: 'Mobile App', url: 'https://app.eskytravel.de/?utm_source=eskytravel.de&utm_medium=menu', target: '_blank'},
      {name: 'Fluggesellschaften', url: 'https://www.eskytravel.de/fluggesellschaften', target: '_blank'},
      {name: 'Flughäfen', url: 'https://www.eskytravel.de/flughafen', target: '_blank'},
      {name: 'Bewertungen', url: 'https://www.eskytravel.de/bewertungen', target: '_blank'},
      {name: 'Reisetipps', url: 'https://www.eskytravel.de/reisetipps', target: '_blank'},
      {name: 'Flugradar', url: 'https://www.eskytravel.de/radar', target: '_blank'},
      {name: 'Reiseregeln', url: 'https://go.eskytravel.de/travel-restrictions', target: '_blank'},
      {name: 'Hilfe und Kontakt', url: 'https://www.eskytravel.de/uber-uns/kontakt', target: '_blank'},
      {name: 'Management von Buchungen', url: 'https://www.eskytravel.de/userzone/login', target: '_blank'}
    ]}
  ],
  fr: [
    {main: {name: 'Ferries', url: affiliateHost}},
    {main: {name: 'Vol', url: 'https://www.esky.fr/vols', target: '_blank'}},
    {main: {name: 'Hébergements', url: 'https://www.esky.fr/hebergements', target: '_blank'}},
    {main: {name: 'Vol+Hôtel', url: 'https://www.esky.fr/vol+hotel', target: '_blank'}},
    {main: {name: 'Voitures', url: 'https://voitures.esky.fr/?preflang=fr&cor=fr&adplat=hpHeader&adcamp=whitelabelFR', target: '_blank'}},
    {main: {name: 'Offres', url: 'https://www.esky.fr/occasion', target: '_blank'}},
    {main: {name: 'Plus'}, values: [
      {name: 'Attractions', url: 'https://www.getyourguide.pl/?partner_id=6PH6GQ5&cmp=FR&visitor-id=K67GRLD0T82H4RS3BKRYO2FQML4JV8CD&locale_autoredirect_optout=true', target: '_blank'},
      {name: 'Assurances', url: 'https://go.esky.fr/assurance', target: '_blank'},
      {name: 'Transferts', url: 'https://esky-fr.mozio.com/fr/?campaign=website', target: '_blank'},
      {name: 'Parkings', url: 'https://eskybymozio.travelcar.com/fr-FR/?utm_source=partner&utm_medium=eskybymobio&utm_campaign=park&utm_content=website-home', target: '_blank'},
      {name: 'Bateaux', url: 'https://www.boataround.com/fr?ref_afi=esky-fr', target: '_blank'},
      {name: 'Application mobile', url: 'https://app.esky.fr/?utm_source=esky.fr&utm_medium=menu', target: '_blank'},
      {name: 'Compagnies aériennes', url: 'https://www.esky.fr/compagnies-aeriennes', target: '_blank'},
      {name: 'Aéroports', url: 'https://www.esky.fr/aeroports', target: '_blank'},
      {name: 'Avis', url: 'esky.fr/avis', target: '_blank'},
      {name: 'Guide de voyage', url: 'https://www.esky.fr/guide-de-voyage', target: '_blank'},
      {name: 'Radar des vols', url: 'https://www.esky.fr/radar', target: '_blank'},
      {name: 'Règles concernant les voyages', url: 'https://go.esky.fr/travel-restrictions', target: '_blank'},
      {name: 'Assistance et contact', url: 'https://www.esky.fr/sur-nous/contact', target: '_blank'},
      {name: 'Gestion de vos réservations', url: 'https://www.esky.fr/userzone/login', target: '_blank'}
    ]}
  ],
  it: [
    {main: {name: 'Traghetti', url: affiliateHost}},
    {main: {name: 'Voli', url: 'https://www.eskytravel.it/voli', target: '_blank'}},
    {main: {name: 'Pernottamenti', url: 'https://www.eskytravel.it/pernottamenti', target: '_blank'}},
    {main: {name: 'Volo+Hotel', url: 'https://www.eskytravel.it/volo+hotel', target: '_blank'}},
    {main: {name: 'Automobili', url: 'https://www.rentalcars.com/?affiliateCode=eskygroup&adplat=hpheader&adcamp=whitelabelIT&cor=IT', target: '_blank'}},
    {main: {name: 'Offerte', url: 'https://www.eskytravel.it/offerte', target: '_blank'}},
    {main: {name: 'Altro'}, values: [
      {name: 'Attrazioni', url: 'https://www.getyourguide.pl/?partner_id=6PH6GQ5&cmp=IT&visitor-id=3VVMZ0VIEAIZVWLZT5LGZ2UJYLARBA0N&locale_autoredirect_optout=true', target: '_blank'},
      {name: 'Assicurazione', url: 'https://go.eskytravel.it/assicurazione', target: '_blank'},
      {name: 'Trasferimenti', url: 'https://eskytravel-it.mozio.com/it-it/?campaign=website', target: '_blank'},
      {name: 'Parcheggi', url: 'https://eskybymozio.travelcar.com/it-IT/?utm_source=partner&utm_medium=eskybymobio&utm_campaign=park-it&utm_content=website-home', target: '_blank'},
      {name: 'Yacht', url: 'https://www.boataround.com/it?ref_afi=esky-it', target: '_blank'},
      {name: 'Applicazione mobile', url: 'https://app.eskytravel.it/?utm_source=eskytravel.it&utm_medium=menu', target: '_blank'},
      {name: 'Compagnie Aeree', url: 'https://www.eskytravel.it/compagnie-aeree', target: '_blank'},
      {name: 'Aeroporti', url: 'https://www.eskytravel.it/aeroporti', target: '_blank'},
      {name: 'Opinioni', url: 'https://www.eskytravel.it/opinioni', target: '_blank'},
      {name: 'Guide turistiche', url: 'https://www.eskytravel.it/guide-turistiche', target: '_blank'},
      {name: 'Stato del volo', url: 'https://www.eskytravel.it/radar', target: '_blank'},
      {name: 'Regole di viaggio', url: 'https://go.eskytravel.it/travel-restrictions', target: '_blank'},
      {name: 'Gestione delle prenotazioni', url: 'https://www.eskytravel.it/userzone/login', target: '_blank'}
    ]}
  ],
  nl: [
    {main: {name: 'Veerboten', url: affiliateHost}},
    {main: {name: 'Vliegtickets', url: 'https://www.esky.nl/vliegtickets', target: '_blank'}},
    {main: {name: 'Verblijf', url: 'https://www.esky.nl/verblijf', target: '_blank'}},
    {main: {name: 'Vlucht+Hotel', url: 'https://www.esky.nl/vlucht+hotel', target: '_blank'}},
    {main: {name: 'Auto\'s', url: 'https://www.rentalcars.com/?affiliateCode=eskygroup&adplat=hpheader&adcamp=whitelabelNL&cor=NL', target: '_blank'}},
    {main: {name: 'Aanbiedingen', url: 'https://www.esky.nl/deals', target: '_blank'}},
    {main: {name: 'Meer'}, values: [
      {name: 'Attracties', url: 'https://www.getyourguide.pl/?partner_id=6PH6GQ5&cmp=NL&visitor-id=TFJ5V7CR746BLA5T6XPPJ0UPHB64VT63&locale_autoredirect_optout=true', target: '_blank'},
      {name: 'Verzekering', url: 'https://go.esky.nl/verzekering', target: '_blank'},
      {name: 'Transfers', url: 'https://esky-nl.mozio.com/nl/?campaign=website', target: '_blank'},
      {name: 'Parkeren', url: 'https://eskybymozio.travelcar.com/nl-NL/?utm_source=partner&utm_medium=eskybymobio&utm_campaign=park-nl&utm_content=website-home', target: '_blank'},
      {name: 'Jachten', url: 'https://www.boataround.com/nl?ref_afi=esky-nl', target: '_blank'},
      {name: 'Mobiele app', url: 'https://app.esky.nl/?utm_source=esky.nl&utm_medium=menu', target: '_blank'},
      {name: 'Luchtvaartmaatschappijen', url: 'https://www.esky.nl/luchtvaartmaatschappijen', target: '_blank'},
      {name: 'Vliegvelden', url: 'https://www.esky.nl/vliegvelden', target: '_blank'},
      {name: 'Beoordelingen', url: 'https://www.esky.nl/reviews', target: '_blank'},
      {name: 'Reisgids', url: 'https://www.esky.nl/reistips', target: '_blank'},
      {name: 'Vluchtenradar', url: 'https://www.esky.nl/radar', target: '_blank'},
      {name: 'Reisregels', url: 'https://go.esky.nl/travel-restrictions', target: '_blank'},
      {name: 'Ondersteuning en contact', url: 'https://www.esky.nl/over-ons/contact', target: '_blank'},
      {name: 'Beheer van boekingen', url: 'https://www.esky.nl/userzone/login ', target: '_blank'}
    ]}
  ],
  pt: [
    {main: {name: 'Balsas', url: affiliateHost}},
    {main: {name: 'Voos baratos', url: 'https://www.esky.pt/voos-baratos', target: '_blank'}},
    {main: {name: 'Alojamentos', url: 'https://www.esky.pt/alojamentos', target: '_blank'}},
    {main: {name: 'Voo+Hotel', url: 'esky.pt/voo+hotel', target: '_blank'}},
    {main: {name: 'Carros', url: 'https://carros.esky.pt/?preflang=pt&adplat=hpHeader&adcamp=whitelabelPT', target: '_blank'}},
    {main: {name: 'Ofertas', url: 'https://www.esky.pt/ofertas', target: '_blank'}},
    {main: {name: 'Mais'}, values: [
      {name: 'Atrações', url: 'https://www.getyourguide.pt/?partner_id=6PH6GQ5&cmp=PT', target: '_blank'},
      {name: 'Seguros', url: 'https://go.esky.pt/seguros', target: '_blank'},
      {name: 'Transferência', url: 'https://esky-pt.mozio.com/pt-br/?campaign=website', target: '_blank'},
      {name: 'Parque de estacionamento', url: 'https://eskybymozio.travelcar.com/pt-PT/?utm_source=partner&utm_medium=eskybymobio&utm_campaign=park-pt&utm_content=website-home', target: '_blank'},
      {name: 'Aplicação de telemóvel', url: 'https://app.esky.pt/?utm_source=esky.pt&utm_medium=menu', target: '_blank'},
      {name: 'Companhias aereas', url: 'https://www.esky.pt/companhias-aereas', target: '_blank'},
      {name: 'Aeroportos', url: 'https://www.esky.pt/aeroportos', target: '_blank'},
      {name: 'Avaliações', url: 'https://www.esky.pt/avaliacoes', target: '_blank'},
      {name: 'Dicas de viagem', url: 'https://www.esky.pt/dicas-de-viagem', target: '_blank'},
      {name: 'Radar de voos', url: 'https://www.esky.pt/radar', target: '_blank'},
      {name: 'Regras de viagem', url: 'https://go.esky.pt/travel-restrictions', target: '_blank'},
      {name: 'Assistência e contacto', url: 'https://www.esky.pt/sobre-nos/contato', target: '_blank'},
      {name: 'Gestão de reservas', url: 'https://www.esky.pt/userzone/login', target: '_blank'}
    ]}
  ],
  pl: [
    {main: {name: 'Promy', url: affiliateHost}},
    {main: {name: 'Tanie loty', url: 'https://www.esky.pl/tanie-loty', target: '_blank'}},
    {main: {name: 'Noclegi', url: 'https://www.esky.pl/noclegi', target: '_blank'}},
    {main: {name: 'Lot+Hotel', url: 'https://www.esky.pl/lot+hotel', target: '_blank'}},
    {main: {name: 'Samochody', url: 'https://samochody.esky.pl/?adplat=hpHeader&adcamp=whitelabelPL', target: '_blank'}},
    {main: {name: 'Okazje', url: 'https://www.esky.pl/okazje', target: '_blank'}},
    {main: {name: 'Więcej'}, values: [
      {name: 'Loty czarterowe', url: 'https://www.esky.pl/loty-czarterowe', target: '_blank'},
      {name: 'Atrakcje', url: 'https://www.getyourguide.pl/?partner_id=6PH6GQ5&amp;cmp=PL', target: '_blank'},
      {name: 'Ubezpieczenia', url: 'https://go.esky.pl/ubezpieczenia', target: '_blank'},
      {name: 'Transfery', url: 'https://esky-pl.mozio.com/?campaign=website', target: '_blank'},
      {name: 'Parkingi', url: 'https://parkingi.esky.pl/', target: '_blank'},
      {name: 'Wydarzenia sportowe', url: 'https://esky-pl.tickets-partners.com/', target: '_blank'},
      {name: 'Jachty', url: 'https://boataround.com/pl?ref_afi=esky-pl', target: '_blank'},
      {name: 'Wizy', url: 'https://go.esky.pl/wizy', target: '_blank'},
      {name: 'Podróże grupowe', url: 'https://www.esky.pl/podroze-grupowe', target: '_blank'},
      {name: 'Aplikacja Mobilna', url: 'https://app.esky.pl/?utm_source=esky.pl&utm_medium=menu', target: '_blank'},
      {name: 'Tanie linie lotnicze', url: 'https://www.esky.pl/linie-lotnicze', target: '_blank'},
      {name: 'Lotniska', url: 'https://www.esky.pl/lotniska', target: '_blank'},
      {name: 'Opinie', url: 'https://www.esky.pl/opinie', target: '_blank'},
      {name: 'Porady dla podróżnych', url: 'https://www.esky.pl/porady-dla-podroznych', target: '_blank'},
      {name: 'Radar lotów', url: 'https://www.esky.pl/radar', target: '_blank'},
      {name: 'Zasady podróżowania', url: 'https://go.esky.pl/travel-restrictions', target: '_blank'},
      {name: 'Pomoc i kontakt', url: 'https://www.esky.pl/o-nas/kontakt', target: '_blank'},
      {name: 'Zarządzaj rezerwacją', url: 'https://www.esky.pl/userzone/login', target: '_blank'}
    ]}
  ]
};

const eskyDomain = {
  en: 'https://www.esky.eu',
  el: 'https://www.esky.gr',
  de: 'https://www.eskytravel.de',
  fr: 'https://www.esky.fr',
  it: 'https://www.eskytravel.it',
  nl: 'https://www.esky.nl',
  pt: 'https://www.esky.pt',
  pl: 'https://www.esky.pl'
};

const getEskyDomain = () => eskyDomain[language] ?? eskyDomain.en;

const getEskyMenu = () => eskyMenu[language] ?? eskyMenu.en;

export {defaultMenu, getEskyMenu, getEskyDomain};
